<template>
    <div class="page-wrap" v-loading="loading">
      <div class="page-aside">
        <ProductsSide @change="changeProduct" :nav="productList" :defaultActive="curProduct.productId"></ProductsSide>
      </div>
      <div class="page-main flex-column">
        <SubNav :router="true" :nav="subNav"></SubNav>
        <div class="main padding-20"><router-view :curProduct='curProduct'></router-view></div>
        <el-backtop target=".main">
          <i class="el-icon-top"></i>
        </el-backtop>
      </div>  
    </div> 
</template>

<script>
import ProductsSide from '@/components/ProductsSide.vue'
import SubNav from '@/components/SubNav.vue'
import crypto from "@/common/crypto";
export default {
  name: 'Entry',
  components: { ProductsSide,SubNav },
  data() {
    return {
     subNav:[{name:'周度预测模型',value:'/forecast/weekModel'},{name:'月度预测模型',value:'/forecast/monthModel'}],
     productList:[],
     curProduct:{},
     loading:false,
    }
  },
  created(){
   this.getProducts()
  },
  methods:{
    getProducts(){
      this.loading=true
      let request=this.$instance.get('/manage/getProductPower',{
          params: {
           ...this.$store.state.basicParams,
           moduleCode:'yu_ce_mo_xing'
          },
      })
      request.then(res=>{
        this.loading=false
        if(res.data.code==0){
          this.productList=res.data.info
          this.checkProAuth()
        }else{
          this.$router.replace({
            path:'/error',
            query:{
              redirect:this.$route.fullPath
            }
          })
        }
      })
      request.catch(()=>{
        this.loading=false
        this.$router.replace({
          path:'/error',
          query:{
            redirect:this.$route.fullPath
          }
        })
      })
      return request
    },
    checkProAuth(){
      if(!this.productList.length){
        this.$alert(`您暂无预测模型的产品权限`, '提示', {
          customClass:"custom-msgbox",
          confirmButtonText: '确定',
          showClose:false,
          type: 'warning'
        })
        .then(() => {
          this.$router.replace({
            path:'/'
          })
        })
        
      }else{
        try {
          let q=JSON.parse(crypto.decrypt(this.$route.query.q))
          if(this.productList.find(v=>{return v.productId==q.productId})){
            this.curProduct={productId:q.productId,targetId:q.targetId}
          }else{
            this.curProduct=this.productList[0]||{}
            this.$alert(`您暂无${q.productName}的预测模型权限`, '提示', {
              customClass:"custom-msgbox",
              confirmButtonText: '确定',
              showClose:false,
              type: 'warning'
            })
            .then(() => {})
          }
        } catch (error) {
          this.curProduct=this.productList[0]||{}
        }
      }
    },
    changeProduct(val){
      if(this.$route.query.q){
        this.$router.replace({
          path: this.$route.path,
        })
      }
      this.curProduct=val
    }
    
  },
  watch: {

  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  
</style>
